import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintWarg,
  mintPixaNFT,
  checkTotalSupply,
  tokenURIfinder,
  checkDaoBalance,
  checkPixaBalance,
  mintThreeWarg,
  checkWargsMinted,
  claimVortical,
  claimVorticalLoop,
  upgradeGem,
  checkDopplers
} from "./utils/interact.js";
import pixagif from 'D:/NFT_development/front_end/new-app-pages/tokens/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo.gif'
import pixapng from 'D:/NFT_development/front_end/new-app-pages/tokens/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo-2.png'
import wargsgif from 'D:/NFT_development/front_end/new-app-pages/tokens/nft-minter-tutorial-main/minter-starter-files/src/PixaWargs.gif'
import powergemgif from 'D:/NFT_development/front_end/new-app-pages/tokens/nft-minter-tutorial-main/minter-starter-files/src/powergem.gif'
import pleasewait from 'D:/NFT_development/front_end/new-app-pages/tokens/nft-minter-tutorial-main/minter-starter-files/src/pleasewait1.PNG'
import pixatokengif from 'D:/NFT_development/front_end/new-app-pages/tokens/nft-minter-tutorial-main/minter-starter-files/src/pixa.gif'
import elxrtokengif from 'D:/NFT_development/front_end/new-app-pages/tokens/nft-minter-tutorial-main/minter-starter-files/src/elxr.gif'
import polypixatokengif from 'D:/NFT_development/front_end/new-app-pages/tokens/nft-minter-tutorial-main/minter-starter-files/src/polypixa.gif'

const Minter = (props) => {

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [ownership, setAreYouOwner] = useState("");
  const [upgradestatus, setupgradeStatus] = useState("");
  const [pixaBalance, setPixaBalance] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [wargs, setWargsMinted] = useState("");
  const [displayRank, setRank] = useState("");
  const [DaoBalance, setDaoBalance] = useState("");
  const [TSpixa, setTotalSupplyPixa] = useState("");
  const [tokenID, setToken] = useState("");
  const [tokenURI, setTokenURI] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [wizstatus, setWizStatus] = useState(""); 
  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    const {currentwargsminted} = await checkWargsMinted();
    setWallet(address);
    setStatus(status);
    setWargsMinted(currentwargsminted)
    setTokenURI(powergemgif);
    setupgradeStatus("currently selected.")    
    const {walletpixabalance} = await checkPixaBalance(address)
    setPixaBalance(walletpixabalance)
    const {TotalSupplyPixa} = await checkTotalSupply()
    setTotalSupplyPixa(TotalSupplyPixa)
    const {wizardao} = await checkDaoBalance()
    setDaoBalance(wizardao)
    addWalletListener(); 
  }, []);


  const onTokenChange = async (num) => {
    setAreYouOwner("")
    setTokenURI(pleasewait)
    setRank("") 
    setAreYouOwner("please wait... ⏱️") 
    setToken(num)
    const { tokenURI, displayRank,ownership } = await tokenURIfinder(num, walletAddress);   
    setTokenURI("https://" + tokenURI.substring(7) + ".gif")
    setRank(displayRank)
    setAreYouOwner(ownership)
  };


  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintWargPressed = async () => { //TODO: implement
      const { status } = await mintWarg(); 
  };

  const onMintThreeWargPressed = async () => { //TODO: implement
      const { status } = await mintThreeWarg();
  };

  const onMintWargPixaPressed = async () => { //TODO: implement
      const { status } = await mintPixaNFT(walletAddress); 
  };

  const onClaimPressed = async () => { //TODO: implement
      const { status } = await claimVortical();
  };

  const onClaimLoopPressed = async () => { //TODO: implement
      const { status } = await claimVorticalLoop();
  };

  const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkDopplers(name);
    setWizStatus(wizstatus);  
  };

  const onUpgrade1Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 1);
  };

  const onUpgrade2Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 2);
  };

  const onUpgrade3Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 3);
  };

  const onUpgrade4Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 4);
  };

  const onUpgrade5Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 5);
  };


  
  return (
    
    <div className="Minter">



<div id="stars"></div>
<div id="stars2"></div>
<div id="stars3"></div>


<h5>By using this webpage you agree to the terms & conditions outlined at the bottom of this page. Disclaimer: Use this site at your own risk.</h5>       

<h1>
          <div>
      <img src={pixapng} alt="Image" height={45} width={100} border={0}/>
      </div>
</h1>

<div id="menubar">
      <h2> <a href="https://wizards.pixanft.com" rel="noreferrer">  🧙‍♂️</a>  <a href="https://witches.pixanft.com" rel="noreferrer">  🧙‍♀️</a> <a href="https://wyverns.pixanft.com" rel="noreferrer">  🐲</a> <a href="https://wargs.pixanft.com" rel="noreferrer">  🐺</a>  <a href="https://brews.pixanft.com" rel="noreferrer">  🍺</a> <a href="https://limitededitions.pixanft.com" rel="noreferrer">  🖼️</a> </h2>
      <h5>Click an emoji to navigate to other pages!</h5>         
      <h2> <a href="https://tokens.pixanft.com" rel="noreferrer">  💰</a>  <a href="https://relics.pixanft.com" rel="noreferrer">  🗡️</a> <a href="https://wizardao.pixanft.com" rel="noreferrer">  🎩</a> <a href="https://tomes.pixanft.com" rel="noreferrer">  📖</a> <a href="https://land.pixanft.com" rel="noreferrer">  🏡</a> <a href="https://badges.pixanft.com" rel="noreferrer">  📛</a> </h2>
</div>          

      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
  
      
      <h1 >💰  The  💰 </h1>
      <h1 >Tokens</h1>
      <h1>_________</h1>
<h4>The PixaRealm is home to three unique tokens: $PIXA, $ELXR and $POLYPIXA.</h4>

<h4>$PIXA, also known as Pixa Token, is considered the gold standard within the PixaRealm and is used as a medium of exchange. It is a valuable token that can be used to purchase and upgrade various NFTs, including PixaWitches and PixaWargs. Uniquely, during each $PIXA transaction 1 additional $PIXA is minted and donated to the WizarDAO. The <a href="https://wizardao.pixanft.com" rel="noreferrer">WizarDAO</a> council can vote on how to use this $PIXA that accumulates in their wallet.</h4>

<h4>$ELXR, also known as Elixir, is a rare resource found within the worlds of the PixaRealm. This resource is especially important in the creation of PixaBrews. This token is considered a precious commodity and is highly sought after by collectors and players. The token is yet to be released, and will be coming in 2023.</h4>

<h4>$POLYPIXA, is a bridged version of $PIXA that became trapped on the Polygon blockchain. It can be used for the same purpose as $PIXA and it can be used to purchase and upgrade NFTs on polygon, such as the PixaBadges.</h4>

<h4>Together, these three tokens form the backbone of the PixaRealm economy, providing players with the means to collect, trade, and upgrade the various NFTs available in the realm.
 For more background lore on the PixaRealm, visit our <a href="https://docs.pixanft.com" rel="noreferrer">docs page</a></h4>

<h4>The $PIXA (released) and $ELXR(yet to be released) are both ERC20 tokens minted on the Ethereum blockchain. $PIXA was created in July 2021, and was distributed through a temporary rewards system for Pixa NFT holders. For more information about the NFT collections that exist in the PixaRealm, visit our <a href="https://pixanft.com" rel="noreferrer">  website</a></h4>

<h4>Scroll down for statistics about each token and your wallet balances.</h4>

<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>


      <h1>$PIXA</h1>
      <h1>_________</h1>
      <h4>The "gold" of the PixaRealm</h4>
      <h4>ENSURE WALLET IS CONNECTED FOR UPDATED BALANCES</h4>

     

      <p>


      </p>
      
        <h1>
          <div>
      <img id="image" src={pixatokengif} alt="Image" height={200} width={200} />
      </div>
</h1>

<h4>Total Supply: {TSpixa} <a href="https://etherscan.io/token/0xeaf211cD484118a23AD71C3F9073189C43d1311c" rel="noreferrer">  $PIXA</a></h4>
<h4>WizarDAO Balance: {DaoBalance} <a href="https://etherscan.io/token/0xeaf211cD484118a23AD71C3F9073189C43d1311c" rel="noreferrer">  $PIXA</a></h4>
<h4>Wallet Balance: {pixaBalance} <a href="https://etherscan.io/token/0xeaf211cD484118a23AD71C3F9073189C43d1311c" rel="noreferrer">  $PIXA</a></h4>

  
<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>





<h1>$ELXR</h1>
      <h1>_________</h1>
      <h4>A "resource" found in the PixaRealm </h4>
      <h4>ENSURE WALLET IS CONNECTED FOR UPDATED BALANCES</h4>

     

      <p>


      </p>
      
        <h1>
          <div>
      <img id="image" src={elxrtokengif} alt="Image" height={200} width={200} />
      </div>
</h1>

<h4>Total Supply: N/A (not released)</h4>
<h4>WizarDAO Balance: N/A (not released) </h4>
<h4>Wallet Balance: N/A (not released)</h4>



      <h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>




<h1>$POLYPIXA</h1>
      <h1>_________</h1>
      <h4>The "rose gold" of the PixaRealm - eternally trapped on the polygon blockchain.</h4>
      <h4>ENSURE WALLET IS CONNECTED FOR UPDATED BALANCES</h4>

     

      <p>


      </p>
      
        <h1>
          <div>
      <img id="image" src={polypixatokengif} alt="Image" height={200} width={200} />
      </div>
</h1>

<h4>Total Supply: 81000 <a href="https://polygonscan.com/token/0xca8c6dea40a6de9be3b4c73a1aefaafdb4754f73" rel="noreferrer">  $POLYPIXA</a></h4>
<h4>WizarDAO Balance: coming soon </h4>
<h4>Wallet Balance: coming soon</h4>

<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<h3>Important Links:</h3>


<h4><a href="https://etherscan.io/token/0xeaf211cD484118a23AD71C3F9073189C43d1311c" rel="noreferrer">  $PIXA Contract</a> </h4>
<h4><a href="https://polygonscan.com/token/0xca8c6dea40a6de9be3b4c73a1aefaafdb4754f73" rel="noreferrer">  $POLYPIXA Contract</a> </h4>

<h4><a href="https://linktr.ee/pixanft" rel="noreferrer">  Linktree</a> </h4>

<h4> <a href="https://opensea.io/PixaDeployer?tab=created" rel="noreferrer">  OpenSea Collections</a> </h4>

<h4><a href="https://discord.gg/pixa" rel="noreferrer">  Discord</a> </h4>

<h4><a href="https://twitter.com/pixa_nft" rel="noreferrer">  Twitter </a></h4>

<br></br>


<h3>Terms & Conditions:</h3>

<h9>(applies to all Pixa sites & smart contracts) - Use this site at your own risk. Pixa is not liable for any losses from the use of this site or associated smart contracts. Your purchase of Pixa NFTs does NOT CONSTITUTE AN INVESTMENT. Purchase of these NFTs from Pixa is meant for entertainment purposes only. Pixa makes no warranties regarding future value, express or implied, to the public or to buyers. Pixa does not offer refunds on purchased NFTs. Only purchase if you wish to own the artwork and nothing else. Only purchase with the current state of the project in mind and the assumption that no future value will be delivered. Pixa NFTs and $PIXA tokens are not currency and do not convey ownership in Pixa or its assets. You are purchasing a digital collectible, we do not guarantee any future development around these NFTs. $PIXA tokens are not shares or securities of any type. Holding a token does not entitle the holder to any ownership or other interest in Pixa (formerly known as PixaLyfe), future business operations, future revenue, or any control or ability to bind the entity. $PIXA tokens are merely a means by which you may be able to utilize certain services such as changing aspects of the artwork in your Pixa NFT. There is no guarantee that the PixaRealm platform will actually be developed, although we guarantee that it is our intention to do so.</h9>

<h1>
          <div>
      <img src={pixagif} alt="Image" height={200} width={200} border={0}/>
      </div>
</h1>

    </div>
    
  );
};



export default Minter;
